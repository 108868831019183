import RepositoryFactory from '@/services/repositories/RepositoryFactory';
import Api from '@/services/Api';
import { ALL_LANGUAGES, NOTIFY_TIME_LONG } from '@/js/constants';
import notify from 'devextreme/ui/notify';
import i18next from 'i18next';
import EventBus from '../../services/event-bus';
import db from '../db';

const userRepository = RepositoryFactory.get('user');

export default {
  namespaced: true,
  state: {
    userName: '',
    accessToken: '',
    refreshToken: '',
    authenticatedUser: {},
    isUserLoggedIn: false,
    wantRememberPassword: false,
    isUserRecoveringPassword: false,
    needUserResetPassword: false,
    languageInterface: '',
    userPasswords: {
      newPassword: '',
      oldPassword: '',
      confirmNewPassword: '',
    },
    showTokenPage: false,
    isCreatingNewUser: false,
    languageProfilePage: '',
    showPrivacyPolicy: false,
  },
  getters: {
    userProfile: (state, getters, rootState) => {
      if (
        state.authenticatedUser?.contacts == null ||
        state.authenticatedUser?.contacts?.length === 0
      ) {
        return {
          avatar: undefined,
          name: undefined,
          surname: undefined,
          email: state.authenticatedUser?.email,
          phone: undefined,
          language: undefined,
          code: undefined,
        };
      }

      const { avatar, name, surname, code } =
        state.authenticatedUser.contacts[
          rootState.contact.contactIndex
        ];

      const { language } =
        state.authenticatedUser.contacts[
          rootState.contact.contactIndex
        ];

      let userCountry = '';

      state.authenticatedUser.contacts[0].companies.forEach(
        (company) => {
          if (company.code === rootState.contact.company) {
            userCountry = company.country;
          }
        }
      );
      return {
        avatar,
        name,
        surname,
        email: state.authenticatedUser.email,
        phone: state.authenticatedUser.contacts[
          rootState.contact.contactIndex
        ].phone[0]
          ? state.authenticatedUser.contacts[
              rootState.contact.contactIndex
            ].phone[0].number
          : '',
        language,
        code,
        userCountry,
      };
    },
    selectedCompanyCode: (state) => {
      return state.authenticatedUser.contacts[0].companies[0].code;
    },
    isAdmin: (state, getters, rootState) => {
      if (
        state.authenticatedUser?.contacts == null ||
        state.authenticatedUser?.contacts?.length === 0
      ) {
        return false;
      }
      return rootState.contact.enabled
        ? state.authenticatedUser.contacts[
            rootState.contact.contactIndex
          ].admin
        : false;
    },
    getLanguage(state) {
      return state.languageInterface;
    },
  },
  mutations: {
    UPDATE_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    UPDATE_AVATAR(state, payload) {
      state.authenticatedUser.contacts[0].avatar = payload;
    },
    UPDATE_NAME(state, payload) {
      state.authenticatedUser.contacts[0].name = payload;
    },
    UPDATE_SURNAME(state, payload) {
      state.authenticatedUser.contacts[0].surname = payload;
    },
    UPDATE_EMAIL(state, payload) {
      state.authenticatedUser.email = payload;
    },
    UPDATE_PHONE(state, payload) {
      state.authenticatedUser.contacts[0].phone[0] = payload;
    },
    UPDATE_AUTHENTICATED_USER(state, payload) {
      state.authenticatedUser = payload;
    },

    UPDATE_IS_USER_LOGGED_IN(state, payload) {
      state.isUserLoggedIn = payload;
    },

    UPDATE_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },

    TOGGLE_WANT_REMEMBER_PASSWORD(state) {
      state.wantRememberPassword = !state.wantRememberPassword;
    },

    SET_USER_RECOVERING_PASSWORD(state) {
      state.isUserRecoveringPassword = true;
    },

    UNSET_USER_RECOVERING_PASSWORD(state) {
      state.isUserRecoveringPassword = false;
    },

    SET_USER_NEED_RESET_PASSWORD(state) {
      state.needUserResetPassword = true;
    },

    UNSET_USER_NEED_RESET_PASSWORD(state) {
      state.needUserResetPassword = false;
    },

    SETUP_NEW_LOGGED_USER(state, payload) {
      const { user, access, refresh, email } = payload;
      state.userName = email;
      state.isUserLoggedIn = true;
      state.accessToken = access;
      state.refreshToken = refresh;
      state.authenticatedUser = user;
      EventBus.$emit('newLoggedUser', access);
    },
    INIT_USER_STORE(state) {
      state.userName = '';
      state.accessToken = '';
      state.refreshToken = '';
      state.isUserLoggedIn = false;
      state.authenticatedUser = {};
      state.wantRememberPassword = false;
      state.isUserRecoveringPassword = false;

      // drop both tables
      db.contacts.clear();
      db.headquarters.clear();
    },
    setUserNewPassword(state, payload) {
      state.userPasswords.newPassword = payload;
    },
    setUserOldPassword(state, payload) {
      state.userPasswords.oldPassword = payload;
    },
    setConfirmNewPassword(state, payload) {
      state.userPasswords.confirmNewPassword = payload;
    },
    UPDATE_LANGUAGE_INTERFACE(state, payload) {
      state.languageInterface = payload;
    },
    setShowTokenPage(state, value) {
      state.showTokenPage = value;
    },
    setShowPrivacyPolicy(state, value) {
      state.showPrivacyPolicy = value;
    },
    setIsCreatingNewUser(state, value) {
      state.isCreatingNewUser = value;
    },
    setLanguageProfilePage(state, value) {
      state.languageProfilePage = value;
    },
    updateUserContacts(state, value) {
      if (value.length > 0) {
        state.authenticatedUser.contacts =
          state.authenticatedUser.contacts.map(
            (ct) => value.find((ctu) => ctu.code === ct.code) || ct
          );
      }
    },
  },
  actions: {
    async getUserData({ rootState }) {
      try {
        const xhr = await Api.getUser(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    initialize(context) {
      if (context.state.isUserLoggedIn) {
        EventBus.$emit('newLoggedUser', context.state.accessToken);
      }
      EventBus.$on('invalidTokenDetected', () => {
        notify(
          {
            message: i18next.t('accessNotAvailable'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
        // context.dispatch('logOut')
      });

      EventBus.$on('authenticationFailed', () => {
        notify(
          {
            message: i18next.t('cannotAccessCompany'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      });

      EventBus.$on('refreshToken', async () => {
        await context.dispatch('refreshToken');

        EventBus.$emit('tokenRefreshed');
      });
    },
    setLanguageProfilePage({ commit }, value) {
      commit('setLanguageProfilePage', value);
    },
    updateLanguageInterface({ commit }, lang) {
      commit('UPDATE_LANGUAGE_INTERFACE', lang);
    },
    async authenticate({ commit }, { email, password, isEmail }) {
      try {
        const { data } = await userRepository.authentication(
          email,
          password,
          isEmail
        );

        if (data.success || data.user) {
          if (
            data.user?.contacts != null &&
            data.user?.contacts.length === 0
          ) {
            return Promise.reject(new Error('contacts_not_found'));
          }

          if (
            data.user?.headquarters != null &&
            data.user?.headquarters.length === 0
          ) {
            return Promise.reject(new Error('empty_headquarters'));
          }

          // Google Analytics - Event login
          window.gtag('event', 'login', {
            method: isEmail ? 'Cliente' : 'AGQ',
          });

          commit('SETUP_NEW_LOGGED_USER', { ...data, email });
        } else {
          return Promise.reject(new Error('invalid_credentials'));
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(new Error(error.errorCodes[0]));
      }
    },

    async requestRecoveryPassword({ commit }, { email }) {
      try {
        await userRepository.resetPassword(email);
        commit('SET_USER_NEED_RESET_PASSWORD');
        commit('UNSET_USER_RECOVERING_PASSWORD');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    translateLanguage({ dispatch }, value) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].value === value) {
          key = ALL_LANGUAGES[lang].key;
        }
      }

      dispatch('setLanguageProfilePage', key);
    },
    async updateAvatar(
      { commit, state, getters, dispatch, rootState },
      { newAvatar }
    ) {
      try {
        dispatch('translateLanguage', state.languageInterface);
        await userRepository.update(
          {
            avatar: newAvatar,
            language: state.languageProfilePage,
            code: getters.userProfile.code,
          },
          state.accessToken,
          rootState.contact.company
        );
        commit('UPDATE_AVATAR', newAvatar);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updatePassword(
      { state, rootState },
      { newPassword, oldPassword }
    ) {
      try {
        await userRepository.updatePassword(
          newPassword,
          oldPassword,
          state.accessToken,
          rootState.contact.company
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateUserContacts({ commit }, value) {
      commit('updateUserContacts', value);
    },
    async updateUserPersonalData(
      { commit, state, getters, rootState },
      { name, surname, phone, language }
    ) {
      try {
        await userRepository.update(
          {
            name,
            surname,
            phone: [{ name: 'main', number: phone }],
            language,
            code: getters.userProfile.code,
          },
          state.accessToken,
          rootState.contact.company,
          rootState.contact.address
        );
        commit('UPDATE_NAME', name);
        commit('UPDATE_SURNAME', surname);
        commit('UPDATE_PHONE', { name: 'main', number: phone });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async confirmChangePassword({ rootState }, payload) {
      try {
        const res = await Api.confirmChangePassword(
          payload,
          rootState.contact.company,
          rootState.contact.address
        );

        if (res.status === 401) {
          return Promise.reject(res.statusText);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setUserNewPassword({ commit }, payload) {
      commit('setUserNewPassword', payload);
    },
    setIsUserLoggedIn({ commit }, value) {
      commit('UPDATE_IS_USER_LOGGED_IN', value);
    },
    setUserOldPassword({ commit }, payload) {
      commit('setUserOldPassword', payload);
    },
    setConfirmNewPassword({ commit }, payload) {
      commit('setConfirmNewPassword', payload);
    },
    logOut({ commit, dispatch }) {
      commit('INIT_USER_STORE');
      setTimeout(() => {
        dispatch('homeFilter/clearFilters', null, {
          root: true,
        });

        dispatch('agronomyFilter/clearFilters', null, {
          root: true,
        });
        dispatch('foodFilter/clearFilters', null, {
          root: true,
        });
        dispatch('foodDetailsFilter/clearFilters', null, {
          root: true,
        });
        dispatch('environmentFilter/clearFilters', null, {
          root: true,
        });
        dispatch('environmentDetailsFilter/clearFilters', null, {
          root: true,
        });
        dispatch('miningFilter/clearFilters', null, {
          root: true,
        });
        dispatch('miningDetailsFilter/clearFilters', null, {
          root: true,
        });
        dispatch('healthSecurityFilter/clearFilters', null, {
          root: true,
        });
        dispatch('healthSecurityDetailsFilter/clearFilters', null, {
          root: true,
        });
      }, 0);
    },
    async refreshToken({ commit, state }) {
      try {
        const { data } = await userRepository.refreshAccessToken(
          state.refreshToken
        );

        commit('UPDATE_ACCESS_TOKEN', data.access);
      } catch (error) {
        commit('INIT_USER_STORE');
      }
    },
    setShowTokenPage({ commit }, value) {
      commit('setShowTokenPage', value);
    },
    setShowPrivacyPolicy({ commit }, value) {
      commit('setShowPrivacyPolicy', value);
    },
    setIsCreatingNewUser({ commit }, value) {
      commit('setIsCreatingNewUser', value);
    },
  },
};
