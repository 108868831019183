<template>
  <f7-page>
    <ReportContainer :key="reloadKey" ref="reportContainer" />
  </f7-page>
</template>

<script>
import ReportContainer from '@/components/PowerBIEmbedded/index.vue';
import { mapActions, mapState } from 'vuex';
import { NOTIFY_TIME_LONG, POWERBI_REPORTS } from '@/js/constants';

export default {
  components: {
    ReportContainer,
  },
  data() {
    return {
      reportId: POWERBI_REPORTS[0]?.reportId || null,
      datasetIds: POWERBI_REPORTS[0]?.datasetId || null,
      reloadKey: 0,
    };
  },
  computed: {
    ...mapState('contact', ['company']),
  },
  watch: {
    company(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reloadKey += 1;
        this.loadReport();
      }
    },
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Food'),
        disabled: false,
        page: 'FoodSamplesPage',
        active: false,
      },
      {
        name: this.$t('mySmartAnalytics'),
        disabled: true,
        page: 'PesticideResidues',
        active: true,
      },
    ]);
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      await this.loadReport();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(error.message)
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async loadReport() {
      if (!this.reportId || !this.datasetIds) {
        console.error('Report ID or Dataset IDs are missing.');
        return;
      }
      await this.getInsertionToken({
        reportId: this.reportId,
        datasetIds: this.datasetIds,
      });
      const { reportContainer } = this.$refs;
      if (reportContainer) {
        await reportContainer.embedReport();
      }
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('PowerBiModules', ['getInsertionToken']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
  },
};
</script>
