var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top"},[_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n    "+_vm._s(_vm.$t('RequestDate'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"marginTop5 recepDate"},[_c('f7-input',{staticClass:"shadow",attrs:{"type":"datepicker","placeholder":_vm.$t('RequestDate'),"clear-button":true,"close-on-select":true,"value":_vm.calculatedRequestDate,"calendar-params":{
        closeOnSelect: true,
        rangePicker: true,
        dateFormat: {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        },
        disabled: _vm.isDisabled,
      }},on:{"calendar:change":_vm.setRequestDate}})],1),_vm._v(" "),_c('div',{staticClass:"marginTop15"},[_c('div',[_vm._v(_vm._s(_vm.$t('status')))]),_vm._v(" "),_c('DxTagBox',{attrs:{"data-source":_vm.statuses,"multiline":true,"search-enabled":true,"placeholder":_vm.$t('status'),"tag-template":"tag","value":_vm.selectedStatuses,"display-expr":"Estado","value-expr":"Estado","on-value-changed":_vm.statusesChanged,"on-opened":_vm.openedStatuses,"on-content-ready":_vm.contentReady,"show-selection-controls":true},scopedSlots:_vm._u([{key:"tag",fn:function({ data }){return [_c('div',{staticClass:"purpleTag"},[_c('b',[_vm._v(_vm._s(data.Estado))]),_vm._v(" "),_c('div',{staticClass:"dx-tag-remove-button xButton"})])]}}])})],1),_vm._v(" "),_c('div',{staticClass:"marginTop15"},[_c('div',[_vm._v("\n      "+_vm._s(_vm.$t('deliveryType'))+"\n    ")]),_vm._v(" "),_c('DxTagBox',{attrs:{"data-source":_vm.deliveryTypes,"multiline":true,"search-enabled":true,"placeholder":_vm.$t('deliveryType'),"tag-template":"tag","value":_vm.selectedDeliveryTypes,"display-expr":"label","value-expr":"id","on-value-changed":_vm.deliveryTypesChanged,"on-opened":_vm.openedDeliveryTypes,"on-content-ready":_vm.contentReady,"show-selection-controls":true},scopedSlots:_vm._u([{key:"tag",fn:function({ data }){return [_c('div',{staticClass:"purpleTag"},[_c('b',[_vm._v(_vm._s(data.label))]),_vm._v(" "),_c('div',{staticClass:"dx-tag-remove-button xButton"})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }