<template>
  <f7-page name="foodSamples">
    <div v-if="loaded">
      <div class="tableContainer">
        <FoodSamplesTable :store="customStore"/>
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import FoodSamplesTable from '../../components/Food/FoodSamplesTable/FoodSamplesTable.vue';

export default {
  components: { FoodSamplesTable },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Food'),
        disabled: false,
        page: 'FoodSamplesPage',
        active: false,
      },
      {
        name: this.$t('agronomySample.exportExcelSampleTable'),
        disabled: true,
        page: 'FoodSamplesPage',
        active: true,
      },
    ]);
    this.setCurrentFilter('food');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('FoodSamplesPage', async (params) => {
        if (params) {
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.customStore = await this.getStore();
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('foodSamples', ['getStore', 'getStoreWithFilters']),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
  },
};
</script>
