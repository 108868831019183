<template>
  <f7-panel right resizable style="width: 300px">
    <f7-view>
      <f7-page class="filter-main-div">
        <div class="title">
          <div class="innerTitle">
            <div>{{ $t('filters') }}</div>
            <div>
              <f7-link panel-toggle="right">
                <i class="f7-icons">multiply</i>
              </f7-link>
            </div>
          </div>
        </div>
        <Home-filters v-if="currentFilter === 'home'" />
        <Agronomy-filters v-if="currentFilter === 'agronomy'" />
        <Food-filters v-if="currentFilter === 'food'" />
        <FoodDetailsFilter v-if="currentFilter === 'foodDetails'" />
        <EnvironmentSamplesFilter
          v-if="currentFilter === 'environment'"
        />
        <EnvironmentDetailsFilter
          v-if="currentFilter === 'environmentDetails'"
        />
        <MiningSamplesFilter v-if="currentFilter === 'mining'" />
        <MiningDetailsFilter
          v-if="currentFilter === 'miningDetails'"
        />
        <HealthSecuritySamplesFilter
          v-if="currentFilter === 'healthSecurity'"
        />

        <HealthSecuritySamplesDetailsFilter
          v-if="currentFilter === 'healthSecurityDetails'"
        />

        <AlertsSettingsParamsFilter
          v-if="currentFilter === 'alertSettingsParams'"
        />

        <AlertsSettingsSampleFilter
          v-if="currentFilter === 'alertSettingsSample'"
        />

        <AlertsReportParamsFilter
          v-if="currentFilter === 'alertReportParams'"
        />

        <AlertsReportSampleFilter
          v-if="currentFilter === 'alertReportSample'"
        />

        <NotificationsDetailFilter
          v-if="currentFilter === 'notificationsDetail'"
        />

        <RiskAnalysisFilter v-if="currentFilter === 'riskAnalysis'" />

        <FoodStatsFilter v-if="currentFilter === 'foodStats'" />
        <AdvancedServicesFilter
          v-if="currentFilter === 'advancedServices'"
        />

        <WeatherGraphFilter v-if="currentFilter === 'weatherGraph'" />
        <GisComparatorFilter
          v-if="currentFilter === 'gisComparator'"
        />
        <ExpertSystemFilters
          v-if="currentFilter === 'expertSystem'"
        />
        <AgronomyNutritionalMonitoringFilter
          v-if="currentFilter === 'agronomyNutritionalMonitoring'"
        />
        <GeotooboxFilter v-if="currentFilter === 'geotoolbox'" />
        <PhytomonitoringFilter
          v-if="currentFilter === 'advancedServicesPhytomonitoring'"
        />
        <EnvironmentStatsFilter
          v-if="currentFilter === 'environmentStats'"
        />
        <OffersListFilters v-if="currentFilter === 'offersList'" />
        <OffersListDetailsFiltersPNTs
          v-if="currentFilter === 'offersDetails'"
        />
        <MaterialRequestFilters
          v-if="currentFilter === 'materialRequest'"
        />
      </f7-page>
      <div
        class="toolbar toolbar-bottom no-hairline"
        style="min-height: 60px"
      >
        <div class="toolbar-inner justify-content-flex-end">
          <div></div>
          <f7-button
            class="button-large button-outline color-gray"
            @click="clearCurrentFilters"
          >
            <span class="text-color-gray">{{ $t('restart') }}</span>
          </f7-button>
          <f7-button
            fill
            panel-toggle="right"
            class="button-large margin-left-half padding-horizontal"
            @click="applyCurrentFilters"
          >
            {{ $t('apply') }}
          </f7-button>
        </div>
      </div>
    </f7-view>
  </f7-panel>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import HomeFilters from './HomeFilters/HomeFilters.vue';
import AgronomyFilters from './AgronomyFilters/AgronomyFilters.vue';
import FoodFilters from './FoodFilters/FoodFilters.vue';
import FoodDetailsFilter from './FoodFilters/DetailsFilter/index.vue';
import EnvironmentSamplesFilter from './EnvironmentFilters/Samples/index.vue';
import EnvironmentDetailsFilter from './EnvironmentFilters/Samples/Details/index.vue';
import EnvironmentStatsFilter from './EnvironmentFilters/Stats/index.vue';
import MiningSamplesFilter from './MiningFilters/Samples/index.vue';
import MiningDetailsFilter from './MiningFilters/Samples/details/index.vue';
import HealthSecuritySamplesFilter from './HealthSecurityFilters/Samples/index.vue';
import HealthSecuritySamplesDetailsFilter from './HealthSecurityFilters/Samples/Details/index.vue';
import AlertsSettingsParamsFilter from './Alerts/Settings/Params/index.vue';
import AlertsSettingsSampleFilter from './Alerts/Settings/Sample/index.vue';
import AlertsReportParamsFilter from './Alerts/Reports/Params/index.vue';
import AlertsReportSampleFilter from './Alerts/Reports/Sample/index.vue';
import NotificationsDetailFilter from './Notifications/Details/index.vue';
import RiskAnalysisFilter from './FoodFilters/RiskAnalysis/index.vue';
import FoodStatsFilter from './FoodFilters/Stats/index.vue';
import AdvancedServicesFilter from './AgronomyFilters/AdvancedServices/index.vue';
import GeotooboxFilter from './AgronomyFilters/AdvancedServices/GeotooboxFilter.vue';
import PhytomonitoringFilter from './AgronomyFilters/AdvancedServices/PhytomonitoringFilter.vue';
import WeatherGraphFilter from './AgronomyFilters/WeatherGraph/index.vue';
import GisComparatorFilter from './GisComparatorFilters/index.vue';
import ExpertSystemFilters from './ExpertSystemFilters/index.vue';
import AgronomyNutritionalMonitoringFilter from './AgronomyFilters/NutritionalMonitoring/index.vue';
import OffersListFilters from './OffersListFilters/OffersListFilters.vue';
import OffersListDetailsFiltersPNTs from './OffersListFilters/OffersListDetailsFiltersPNTs.vue';
import MaterialRequestFilters from './MaterialRequestFilters/MaterialRequestFilters.vue';

export default {
  components: {
    HomeFilters,
    AgronomyFilters,
    FoodFilters,
    FoodDetailsFilter,
    EnvironmentSamplesFilter,
    EnvironmentDetailsFilter,
    EnvironmentStatsFilter,
    MiningSamplesFilter,
    MiningDetailsFilter,
    HealthSecuritySamplesFilter,
    HealthSecuritySamplesDetailsFilter,
    AlertsSettingsParamsFilter,
    AlertsSettingsSampleFilter,
    AlertsReportParamsFilter,
    AlertsReportSampleFilter,
    NotificationsDetailFilter,
    RiskAnalysisFilter,
    FoodStatsFilter,
    AdvancedServicesFilter,
    WeatherGraphFilter,
    GisComparatorFilter,
    ExpertSystemFilters,
    AgronomyNutritionalMonitoringFilter,
    GeotooboxFilter,
    PhytomonitoringFilter,
    OffersListFilters,
    OffersListDetailsFiltersPNTs,
    MaterialRequestFilters,
  },
  data() {
    return {};
  },

  computed: {
    ...mapState('filter', ['currentFilter']),
  },
  methods: {
    ...mapActions('filter', [
      'clearCurrentFilters',
      'applyCurrentFilters',
    ]),
  },
};
</script>
<style scoped lang="scss">
@import './FilterPanel.styles.scss';
</style>
