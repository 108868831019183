<template>
  <f7-page>
    <div v-if="loaded">
      <OfferListSummary />
      <br />
      <div v-if="!isQCA">
        <div
          v-if="showDetailsAnalytics"
          class="card no-margin margin-bottom"
        >
          <div class="card-header">{{ this.$t('Analytics') }}</div>
          <OfferListTableDetails :store="showDetailsAnalytics" />
        </div>

        <div
          v-if="!isQMT && showDetailsNoAnalytics"
          class="card no-margin margin-bottom"
        >
          <div class="card-header">{{ $t('noAnalytics') }}</div>

          <OfferListTableDetailsNoAnalytics
            :store="detailsNoAnalytics"
          />
        </div>
      </div>

      <DxTabPanel
        v-if="isQCA"
        class="card no-margin margin-bottom"
        :selected-index="selectedIndex"
        :data-source="tabsConfig"
        :loop="false"
        :animation-enabled="true"
        :swipe-enabled="false"
        @optionChanged="setSelectedIndex"
      >
        <template #title="{ data }">
          <span>{{ data.text }}</span>
        </template>

        <template #item="{ data }">
          <div>
            <div
              v-if="data.selectedIndex === 1"
              class="card no-margin"
            >
              <OfferListTableDetails
                v-if="isQCA && showDetailsAnalytics"
                :store="showDetailsAnalytics"
              />
            </div>

            <div
              v-if="data.selectedIndex === 2"
              class="card no-margin"
            >
              <OfferListTableDetailsPntsPts
                v-if="isQCA && ShowDetailsPNT"
                :store="ShowDetailsPNT"
              />
            </div>

            <div
              v-if="data.selectedIndex === 3"
              class="card no-margin"
            >
              <OfferListTableDetailsNoAnalytics
                v-if="isQCA && showDetailsNoAnalytics"
                :store="detailsNoAnalytics"
              />
            </div>
          </div>
        </template>
      </DxTabPanel>
      <NotificationPrices v-if="priceAlert" id="notificationPrices" />
      <GoBack
        class="margin-top"
        :url-name="urlName"
        :go-to-url-name="true"
      />
    </div>
  </f7-page>
</template>

<script>
import OfferListSummary from '@/components/OffersList/OfferListSummary/index.vue';
import OfferListTableDetails from '@/components/OffersList/OfferListDetails/OfferListTableDetails.vue';
import OfferListTableDetailsPntsPts from '@/components/OffersList/OfferListDetails/OfferListTableDetailsPntsPts.vue';
import { mapState, mapActions } from 'vuex';
import NotificationPrices from '@/components/NotificationPrices/index.vue';
// import NotificationSpinner from '@/components/NotificationSpinner';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import OfferListTableDetailsNoAnalytics from '@/components/OffersList/OfferListDetails/OfferListTableDetails.NoAnalytics.vue';
import { DxTabPanel } from 'devextreme-vue/tab-panel';

export default {
  components: {
    OfferListSummary,
    OfferListTableDetails,
    GoBack,
    // NotificationSpinner,
    NotificationPrices,
    OfferListTableDetailsNoAnalytics,
    OfferListTableDetailsPntsPts,
    DxTabPanel,
  },
  data() {
    return {
      loaded: false,
      detailsAnalytics: null,
      detailsNoAnalytics: null,
      showDetailsPNT: null,
      selectedIndex: 0,
      selectedRoute: 0,
      priceAlert: false,
      noAnalytics: this.$t('noAnalytics'),
    };
  },
  computed: {
    urlName() {
      if (this.lastRouteGeotoolboxSamples) {
        return 'GisActionsPage';
      }
      this.get_empresas_centro_produccion();
      return this.lastRouteHome ? 'Home' : 'offersList';
    },
    ShowDetailsPNT() {
      const showDetailsPNTFiltered = this.detallesPresupuestos.filter(
        (detalle) =>
          detalle.DesglosePresupuestoAbierto !== null &&
          detalle.DesglosePresupuestoAbierto.length > 0
      );
      const detallesAplanados = showDetailsPNTFiltered.flatMap(
        (detalle) => detalle.DesglosePresupuestoAbierto
      );
      this.setSubFamilias(detallesAplanados);
      const onlyOneSubFamilia = detallesAplanados.filter(
        (detalle) => {
          return (
            !this.tipoMuestras[0] ||
            detalle.SubfamiliaTM === this.tipoMuestras[0]
          );
        }
      );
      const pntsCompletos = onlyOneSubFamilia.map((detalle) => {
        const empresa = this.centroProduccion.filter(
          (e) => e.idEmpresa === detalle.IdEmpresa
        );
        return {
          ...detalle,
          centroProduccion: empresa,
          PrecioBrutoCodDivisa: `${detalle.Precio} ${this.divisa}`,
        };
      });
      const padres = pntsCompletos.filter(
        (detalle) => detalle.idPNT !== null
      );
      const hijos = pntsCompletos.filter(
        (detalle) => detalle.idPNT === null && detalle.IdPnt !== null
      );
      const pntsOrdenados = [];
      padres.forEach((padre) => {
        pntsOrdenados.push(padre);
        const hijosDelPadre = hijos.filter(
          (hijo) => hijo.IdPnt === padre.idPNT
        );
        pntsOrdenados.push(...hijosDelPadre);
      });
      this.setPriceAlert();
      return pntsOrdenados;
    },
    showDetailsAnalytics() {
      // Filtrar los detalles que son analíticos y no tienen desglose de presupuesto abierto
      let filteredDetails = this.detallesPresupuestos.filter(
        (detalle) =>
          detalle.ATanalitico === true &&
          detalle.DesglosePresupuestoAbierto === null
      );
      // Mapear los detalles filtrados para agregar el campo PrecioBrutoCodDivisa
      filteredDetails = filteredDetails.map((detalle) => ({
        ...detalle,
        PrecioBrutoCodDivisa: `${detalle.PrecioBruto} ${this.divisa}`,
      }));
      this.showDetailsNoAnalytics();
      return filteredDetails;
    },
    tabsConfig() {
      const tabsConfig = [
        {
          id: 0,
          text: this.$t('packAnalytical'),
          selectedIndex: 1,
        },
      ];

      tabsConfig.push({
        id: 1,
        text: this.$t('alert.parameters'),
        selectedIndex: 2,
      });
      if (this.showDetailsNoAnalytics) {
        tabsConfig.push({
          id: 2,
          text: this.$t('noAnalytics'),
          selectedIndex: 3,
        });
      }
      return tabsConfig;
    },
    ...mapState('Offers', [
      'detallesPresupuestos',
      'isQCA',
      'isQMT',
      'centroProduccion',
      'detallesPresupuestosSummary',
      'divisa',
      'analisisTipoDescrip',
      'detailsNoAnalyticsDescro',
    ]),
    ...mapState('OffersListFilters', ['tipoMuestras']),
    ...mapState('filter', ['currentSampleId']),
    ...mapState('samples', [
      'lastRouteHome',
      'lastRouteGeotoolboxSamples',
    ]),
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('offersDetails'),
        disabled: false,
        page: 'offersDetails',
        active: true,
      },
    ]);
    this.setCurrentFilter('offersDetails');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      this.applyCurrentFilters();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(error)
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      setTimeout(() => {
        this.$f7.preloader.hide();
      }, 2000);
      this.loaded = true;
    }
  },
  methods: {
    async showDetailsNoAnalytics() {
      try {
        // Primero, obtenemos la descripción y establecemos los detalles sin analítica
        await this.setDetailsNoAnalytics(
          this.detallesPresupuestos.filter(
            (detalle) => detalle.ATanalitico === false
          )
        );
        await this.obtenerDescripcionNoAnalytics();
        // Luego, filtramos y transformamos los detalles una vez que la descripción se ha obtenido
        const detallesFiltrados = this.detailsNoAnalyticsDescro
          .filter((detalle) => detalle.ATanalitico === false)
          .map((detalle) => {
            const analisis = this.analisisTipoDescrip.find(
              (a) => a.AnalisisTipo === detalle.Articulo
            );
            return {
              ...detalle,
              PrecioBrutoCodDivisa: `${detalle.PrecioBruto} ${this.divisa}`,
              descripcion: analisis
                ? analisis.CodAnalisisTipo
                : undefined,
            };
          });
        this.detailsNoAnalytics = detallesFiltrados;
        return this.detailsNoAnalytics.length > 0
          ? this.detailsNoAnalytics
          : false;
      } catch {
        return false;
      }
    },
    async obtenerDescripcionNoAnalytics() {
      await this.fetchAnalisisTipo({
        idEmpresa:
          this.detailsNoAnalyticsDescro[0]
            .IdCentroProduccionSeleccionado,
        idFamiliaTM: this.detailsNoAnalyticsDescro[0].IdFamilia,
        idAgrupacionFamiliaTM: 0,
        idSubFamiliaTM: this.detailsNoAnalyticsDescro[0].IdSubFamilia,
        idCliente: 8120,
        idGrupo: 0,
      });
      return this.detailsNoAnalyticsDescro;
    },
    setSelectedIndex(e) {
      if (e.name === 'selectedIndex') {
        this.selectedRoute = e.value;
      }
    },
    setPriceAlert() {
      this.priceAlert = true;
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
    ...mapActions('Offers', [
      'get_empresas_centro_produccion',
      'setSubFamilias',
      'fetchAnalisisTipo',
      'setDetailsNoAnalytics',
    ]),
  },
};
</script>
<style scoped lang="scss">
@import './offers-details-page.Styles.scss';
</style>
