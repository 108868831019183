import Api from '@/services/Api';
import db from '@/store/db';

export default {
  namespaced: true,
  state: {
    insertionToken: '',
    reportId: '',
    availableCountries: [],
  },
  actions: {
    async getInsertionToken(
      { commit, rootState },
      { reportId = null, datasetIds = null }
    ) {
      try {
        const response = await Api.getInsertionToken(
          rootState.contact.company,
          reportId,
          datasetIds
        );
        const responsev2 = JSON.parse(response.response);
        commit('setInsertionToken', responsev2.token);
        commit('setReportId', reportId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchCountriesAvailables({ commit }) {
      try {
        const cachedCountries = await db.availableCountries.toArray();
        if (cachedCountries.length > 0) {
          commit('setAvailableCountries', cachedCountries[0].data);
          return Promise.resolve();
        }

        const response = await Api.getCountriesAvailables();
        const countries =
          typeof response.response === 'string'
            ? JSON.parse(response.response)
            : response.response;

        if (Array.isArray(countries)) {
          await db.availableCountries.clear();
          await db.availableCountries.add({ data: countries });
          commit('setAvailableCountries', countries);
        } else {
          return Promise.reject(
            new Error(
              'The response for countries is not a valid array.'
            )
          );
        }

        return Promise.resolve();
      } catch (error) {
        console.error('Error fetching available countries:', error);
        return Promise.reject(error);
      }
    },
    setInsertionToken({ commit }, value) {
      commit('setInsertionToken', value);
    },
    setReportId({ commit }, value) {
      commit('setReportId', value);
    },
    setAvailableCountries({ commit }, value) {
      commit('setAvailableCountries', value);
    },
    resetData({ commit }) {
      commit('resetData');
    },
  },
  mutations: {
    setInsertionToken(state, value) {
      state.insertionToken = value;
    },
    setReportId(state, value) {
      state.reportId = value;
    },
    setAvailableCountries(state, value) {
      state.availableCountries = value;
    },
    resetData(state) {
      state.insertionToken = '';
      state.reportId = '';
      state.availableCountries = [];
    },
  },
};
