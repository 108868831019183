<template>
  <div class="margin-top">
    <div class="margin-top-half">
      <div class="label-input">{{ $t('status') }}</div>
      <DxTagBox
        :data-source="statuses"
        :multiline="false"
        :search-enabled="true"
        :placeholder="$t('status')"
        tag-template="tag"
        :value="selectedStatuses"
        display-expr="Estado"
        value-expr="CodEstado"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="false"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.Estado }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';

export default {
  components: { DxTagBox },
  data() {
    return {
      statuses: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Offers', ['presupuestos']),
    selectedStatuses: {
      get() {
        return this.$store.state.OffersListFilters.selectedStatuses;
      },
      set(value) {
        this.setSelectedStatuses(value);
      },
    },
  },
  methods: {
    async openedStatuses() {
      const statuses = this.extractStatusesFromOffers(
        this.presupuestos
      );
      this.statuses = new DataSource({
        store: statuses,
        key: 'CodEstado',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    extractStatusesFromOffers(presupuestos) {
      const statusSet = new Set();
      presupuestos.forEach((presupuesto) => {
        if (
          presupuesto.Estado &&
          presupuesto.CodEstado &&
          presupuesto.CodEstado !== 2 &&
          presupuesto.Estado !== 'Rechazado'
        ) {
          statusSet.add(
            JSON.stringify({
              CodEstado: presupuesto.CodEstado,
              Estado: presupuesto.Estado,
            })
          );
        }
      });
      return Array.from(statusSet).map((item) => JSON.parse(item));
    },
    statusesChanged(e) {
      const selectedCodEstados = e.value;
      this.setSelectedStatuses(selectedCodEstados);
    },
    contentReady() {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });
      this.loaded = false;
    },
    ...mapActions('OffersListFilters', ['setSelectedStatuses']),
  },
};
</script>
