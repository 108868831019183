<template>
  <div>
    <div class="content-setting">
      <div class="input-full">
        <div class="card no-margin margin-bottom usuarioinfo">
          <div class="card-header">
            {{ $t('userInfo') }}
          </div>
          <div class="card-content padding">
            <div class="margin-bottom">
              <label for="file" class="import-file">
                <div v-if="userProfile.avatar" class="editUser">
                  <Avatar
                    :key="avatarKey"
                    :size="150"
                    :src="userProfile.avatar"
                  />
                </div>
                <svg
                  class="box__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="43"
                  viewBox="0 0 50 43"
                >
                  <path
                    fill="#C8CCE5"
                    d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"
                  ></path>
                </svg>
              </label>
              <input
                id="file"
                ref="avatarUploader"
                style="display: none"
                type="file"
                name="file"
                accept="image/png,image/jpg,image/jpeg"
                @change="onImageSelected"
              />
              <div class="small margin-top-half text-align-center">
                <i style="color: red">*</i>
                Formatos: {{ allowedPictureExtension }}
              </div>
            </div>

            <div>
              <form @submit.prevent="saveNewPersonalData">
                <DxForm
                  id="user-personal-info"
                  :form-data="userProfile"
                  label-location="top"
                >
                  <DxItem
                    :editor-options="{ disabled: !isAdmin }"
                    data-field="name"
                  >
                    <DxLabel :text="$t('name')" />
                  </DxItem>

                  <DxItem
                    :editor-options="{ disabled: !isAdmin }"
                    data-field="surname"
                  >
                    <DxLabel :text="$t('surname')" />
                  </DxItem>

                  <DxItem
                    :editor-options="{
                      mask: '+34 000-000-000',
                      disabled: !isAdmin,
                      maskRules: { X: /[02-9]/ },
                    }"
                    data-field="phone"
                  >
                    <DxLabel :text="$t('phone')" />
                  </DxItem>
                  <DxItem
                    data-field="email"
                    :editor-options="{ disabled: true }"
                  >
                    <DxLabel :text="$t('email')" />
                  </DxItem>
                </DxForm>

                <DxForm
                  id="user-config"
                  label-location="top"
                  class="margin-top-half margin-bottom"
                >
                  <DxItem
                    :editor-options="{
                      value: $root.$i18n.i18next.language,
                      items: languages,
                      valueExpr: 'value',
                      disabled: false,
                      displayExpr: translateLanguageOption,
                      onValueChanged: languageChanged,
                    }"
                    data-field="Language"
                    editor-type="dxSelectBox"
                  >
                    <DxLabel :text="$t('language')" />
                  </DxItem>
                </DxForm>

                <div>
                  <DxForm
                    :form-data="userPasswords"
                    label-location="top"
                    class="margin-top"
                  >
                    <DxSimpleItem data-field="oldPassword">
                      <DxLabel :text="$t('oldPassword')" />
                      <DxRequiredRule
                        :message="$t('old_password_required')"
                      />
                    </DxSimpleItem>
                  </DxForm>

                  <DxForm
                    :form-data="userPasswords"
                    label-location="top"
                    class="margin-top-half"
                  >
                    <DxSimpleItem data-field="newPassword">
                      <DxLabel :text="$t('new_password')" />
                      <DxRequiredRule
                        :message="$t('new_password_required')"
                      />
                    </DxSimpleItem>
                  </DxForm>

                  <DxForm
                    :form-data="userPasswords"
                    label-location="top"
                    class="margin-top-half"
                  >
                    <DxSimpleItem data-field="confirmNewPassword">
                      <DxLabel :text="$t('confirm_new_password')" />
                      <DxRequiredRule
                        :message="$t('confirm_new_password_required')"
                      />
                      <DxCompareRule
                        :comparison-target="passwordComparison"
                        :message="$t('new_password_not_match')"
                      />
                    </DxSimpleItem>
                  </DxForm>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card no-margin margin-bottom">
        <div class="card-header">
          {{ $t('clientData') }}
        </div>

        <div class="datoscliente">
          <div>
            <form @submit.prevent="submitForm">
              <DxForm
                id="client-info-form"
                label-location="top"
                :form-data="contactInfo"
              >
                <DxItem
                  :editor-options="{ disabled: true, width: '160px' }"
                  data-field="country"
                >
                  <DxLabel :text="$t('country')" />
                </DxItem>

                <DxItem
                  :editor-options="{ disabled: true, width: '130px' }"
                  data-field="language"
                >
                  <DxLabel :text="$t('language')" />
                </DxItem>
              </DxForm>
            </form>
          </div>

          <div>
            <form @submit.prevent="submitForm">
              <DxForm
                id="client-info-form"
                label-location="top"
                :form-data="contactInfo"
              >
                <DxItem
                  :editor-options="{ disabled: true, width: '120px' }"
                  data-field="company"
                >
                  <DxLabel :text="$t('clientCode')" />
                </DxItem>

                <DxItem
                  :editor-options="{ disabled: true, width: '120px' }"
                  data-field="currency"
                >
                  <DxLabel :text="$t('currency')" />
                </DxItem>
              </DxForm>
            </form>
          </div>

          <div>
            <form @submit.prevent="submitForm">
              <DxForm
                id="client-info-form"
                label-location="top"
                :form-data="contactInfo"
              >
                <DxItem
                  :editor-options="{ disabled: true, width: '190%' }"
                  data-field="companyName"
                >
                  <DxLabel :text="$t('companyName')" />
                </DxItem>

                <DxItem
                  :editor-options="{ disabled: true }"
                  data-field="identification_number"
                >
                  <DxLabel :text="$t('NIF')" />
                </DxItem>
              </DxForm>
            </form>
          </div>

          <div>
            <form @submit.prevent="submitForm">
              <DxForm
                id="client-info-form"
                label-location="top"
                :form-data="contactInfo"
              >
                <DxItem
                  :editor-options="{ disabled: true }"
                  data-field="agent"
                >
                  <DxLabel :text="$t('agent')" />
                </DxItem>
              </DxForm>
            </form>
          </div>
        </div>
      </div>

      <div class="card no-margin margin-bottom">
        <div class="card-header">
          {{ $t('addressData') }}
        </div>
        <div></div>
        <div class="datosdirecciones">
          <form @submit.prevent="submitForm">
            <DxForm label-location="top" :form-data="contactInfo">
              <DxItem
                :editor-options="{ disabled: true }"
                data-field="addressName"
              >
                <DxLabel :text="$t('description')" />
              </DxItem>

              <DxItem
                :editor-options="{ disabled: true, width: '150px' }"
                data-field="fax"
              >
                <DxLabel :text="$t('Fax')" />
              </DxItem>
            </DxForm>
          </form>

          <div>
            <form @submit.prevent="submitForm">
              <DxForm label-location="top" :form-data="contactInfo">
                <DxItem
                  :editor-options="{ disabled: true }"
                  data-field="address"
                >
                  <DxLabel :text="$t('address')" />
                </DxItem>

                <DxItem
                  :editor-options="{ disabled: true, width: '150px' }"
                  data-field="phone"
                >
                  <DxLabel :text="$t('phone')" />
                </DxItem>
              </DxForm>
            </form>
          </div>

          <div>
            <form @submit.prevent="submitForm">
              <DxForm label-location="top" :form-data="contactInfo">
                <DxItem
                  :editor-options="{ disabled: true, width: '200px' }"
                  data-field="city"
                >
                  <DxLabel :text="$t('city')" />
                </DxItem>

                <DxItem
                  class="comuna"
                  :editor-options="{ disabled: true }"
                  :data-field="dataField"
                >
                  <DxLabel :text="labelText" />
                </DxItem>
              </DxForm>
            </form>
          </div>
        </div>
      </div>

      <div class="card no-margin margin-bottom" v-if="isAdmin">
        <div class="card-header">
          {{ $t('contactManagement') }}
        </div>
        <div class="gestioncontactos">
          <div v-if="loaded">
            <UsersTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxRequiredRule,
  DxCompareRule,
  DxLabel,
} from 'devextreme-vue/form';
import Avatar from 'vue-avatar';
import {
  SECONDARY_COLOR,
  ALL_LANGUAGES,
  NOTIFY_TIME_LONG,
} from '@/js/constants';
import { mapActions, mapState, mapGetters } from 'vuex';
import ProfilePage from '@/pages/profile-page.vue';
import UsersTable from '@/components/UsersTable/index.vue';

export default {
  name: 'Profile',
  components: {
    DxForm,
    DxItem,
    DxSimpleItem,
    DxRequiredRule,
    DxCompareRule,
    DxLabel,
    Avatar,
    UsersTable,
  },
  data() {
    return {
      ProfilePage,
      contraseña: false,
      languages: ALL_LANGUAGES,
      languageSelected: '',
      loaded: false,
      themes: ['Light'],
      isUserChangingPassword: false,
      SECONDARY_COLOR,
      selectedImage: this.avatar,
      selectedImageName: '',
      isSaveConfigDisabled: true,
      savePersonalButtonOptions: {
        text: `${this.$t('save_changes')}`,
        type: 'success',
        useSubmitBehavior: true,
      },
      submitChangePasswordOptions: {
        text: `${this.$t('save_new_password')}`,
        type: 'success',
        useSubmitBehavior: true,
      },
      submitConfigOptions: {
        text: `${this.$t('save_configuration')}`,
        type: 'success',
        useSubmitBehavior: true,
        disabled: true,
      },
      cancelChangePasswordOptions: {
        text: `${this.$t('cancel')}`,
        type: 'danger',
        onClick: this.hideChangePassword,
      },
      passwordOptions: {
        mode: 'password',
      },
      allowedPictureExtension: '.png, .jpg, .jpeg',
      avatarKey: 'avatar-key',
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getUsers();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'userProfile']),
    ...mapGetters('company', ['userInfo']),
    ...mapState('user', ['userPasswords']),
    ...mapState('contact', [
      'showNewContactPopup',
      'enabled',
      'company',
      'companyName',
      'address',
      'addressName',
    ]),
    ...mapGetters('contact', ['contactInfo']),

    labelText() {
      return this.contactInfo.country === 'CL'
        ? this.$t('district')
        : this.$t('Provincia');
    },
    dataField() {
      return this.contactInfo.country === 'CL'
        ? 'desc_comuna'
        : 'province';
    },
  },
  methods: {
    ...mapActions('user', [
      'updatePassword',
      'updateAvatar',
      'updateUserPersonalData',
      'setUserNewPassword',
      'setUserOldPassword',
      'setConfirmNewPassword',
    ]),

    showChangePassword() {
      this.isUserChangingPassword = true;
    },
    goToCreateUserPage() {
      this.$f7router.navigate('/users/create', {
        transition: 'f7-fade',
      });
    },
    switchIsChangingPassword() {
      this.isUserChangingPassword = !this.isUserChangingPassword;
    },
    hideChangePassword() {
      this.isUserChangingPassword = false;
    },
    initAvatarForm() {
      this.$refs.avatarUploader.value = null;
    },
    async saveNewPassword() {
      const self = this;
      try {
        if (!this.isUserChangingPassword) return;
        this.$notifyDX(
          {
            message: this.$t('updating_new_password'),
            width: 450,
          },
          'info',
          2000
        );

        await this.updatePassword({
          newPassword: this.userPasswords.newPassword,
          oldPassword: this.userPasswords.oldPassword,
        });
        setTimeout(() => {
          self.$f7.dialog.close();
        }, 5000);
        this.$notifyDX(
          {
            message: this.$t('password_updated'),
            width: 450,
          },
          'success',
          2000
        );
      } catch (error) {
        this.$f7.dialog.close();

        this.$notifyDX(
          {
            message: this.$t(error.errorCodes[0]),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.initUserPasswordsForm();
      }
    },
    onImageSelected(event) {
      const reader = new FileReader();
      const newImage = event.target.files[0];
      this.selectedImageName = newImage.name.replace(
        /(.{13})..+/,
        '$1...'
      );

      reader.onload = (e) => {
        this.selectedImage = e.target.result;
        this.userProfile.avatar = this.selectedImage;
        this.avatarKey = `avatar-key-${Math.random()}`;
        this.$emit('avatar-changed', this.selectedImage);
      };

      reader.onerror = (error) => {
        this.$f7.dialog.alert(error);
      };

      reader.readAsDataURL(newImage);
    },
    async onUpdatePhoto() {
      const self = this;
      try {
        if (!this.selectedImage) {
          this.$notifyDX(
            {
              message: this.$t('profilePhotoNotSelected'),
              width: 150,
            },
            'error',
            NOTIFY_TIME_LONG
          );
          return;
        }

        this.$notifyDX(
          {
            message: this.$t('updating_avatar'),
            width: 150,
          },
          'info',
          2000
        );

        await this.updateAvatar({ newAvatar: this.selectedImage });
        self.$f7.dialog.close();
        this.$notifyDX(
          {
            message: this.$t('avatar_updated'),
            width: 150,
          },
          'success',
          2000
        );
        this.selectedImage = undefined;
      } catch (error) {
        this.$f7.dialog.close();

        self.$f7.dialog.close();
        this.$notifyDX(
          {
            message: this.$t(error.errorCodes[0]),
            width: 150,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.initAvatarForm();
      }
    },
    getPhotoExtension(photoB64) {
      return photoB64.split(';base64,')[0].split('image/')[1];
    },
    languageChanged(e) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].value === e.value) {
          key = ALL_LANGUAGES[lang].key;
        }
      }
      this.$emit('languageChanged', { key, value: e.value });
    },
    passwordComparison() {
      return this.userPasswords.newPassword;
    },
    translateLanguageOption(e) {
      return `${this.$t(e.value)}`;
    },
    translateLang(row) {
      return this.$t(`${row.language}`);
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('company', ['getUsers']),
  },
};
</script>

<style lang="scss" scoped>
@import './Profile.styles.scss';
</style>
