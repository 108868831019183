import ProfilePage from '@/pages/profile-page.vue';
import HomePage from '@/pages/home.vue';
import NotFoundPage from '@/pages/404.vue';
import AgronomySamplesPage from '@/pages/Agronomy/agro-samples-page.vue';
import EnvironmentSamplesPage from '@/pages/Environment/index.vue';
import EnvironmentStatsPage from '@/pages/Environment/stats/index.vue';
import FoodSamplesPage from '@/pages/Food/food-samples-page.vue';
import MiningSamplesPage from '@/pages/Mining/index.vue';
import HealthAndSecuritySamplesPage from '@/pages/HealthAndSecurity/index.vue';
import UsersManagementPage from '@/pages/Users/users-management-page.vue';
import UsersCreatePage from '@/pages/Users/users-create-page.vue';
import UsersEditPage from '@/pages/Users/users-edit-page.vue';
import RiskAnalysisPage from '@/pages/Food/RiskAnalysis/index.vue';
import FoodStatsPage from '@/pages/Food/Stats/index.vue';
import NutritionalMonitoringPage from '@/pages/Agronomy/NutritionalMonitoring/index.vue';
import AdvancedServicesPage from '@/pages/Agronomy/AdvancedServices/index.vue';
import DashboardPage from '@/pages/Agronomy/AdvancedServices/Dashboard/index.vue';
import NutritionalMonitoring from '@/pages/Agronomy/AdvancedServices/NutritionalMonitoring/index.vue';
import AdvancedServicesManagementPage from '@/pages/Agronomy/AdvancedServices/Management/index.vue';
import AdvancedServicesParcelRegistrationPage from '@/pages/Agronomy/AdvancedServices/Management/RegisterParcel/index.vue';
import AdvancedServicesStationRegistrationPage from '@/pages/Agronomy/AdvancedServices/Management/RegisterStation/index.vue';
import TokenRecoveryPage from '@/pages/Users/TokenRecoveryPage/index.vue';
import WeatherGraphsPage from '@/pages/WeatherGraphs/index.vue';
import TimeDimensionMapPage from '@/pages/Agronomy/AdvancedServices/Dashboard/TimeDimensionMap/index.vue';
import GisComparatorPage from '@/pages/Agronomy/AdvancedServices/Dashboard/GisComparator/index.vue';
import TimeDimensionComparatorPage from '@/pages/Agronomy/AdvancedServices/Dashboard/TimeDimensionComparator/index.vue';
import MIRDetail from '@/pages/Agronomy/AdvancedServices/MIRDetail/index.vue';
import MIRStationsPage from '@/pages/Agronomy/AdvancedServices/MIRStations/index.vue';
import GisActionsPage from '@/pages/Agronomy/AdvancedServices/GisActions/index.vue';

// Documentation
import FoodDocumentationPage from '@/pages/Food/Documentation/index.vue';
import AgronomyDocumentationPage from '@/pages/Agronomy/Documentation/index.vue';
import EnvironmentDocumentationPage from '@/pages/Environment/Documentation/index.vue';
import MiningDocumentationPage from '@/pages/Mining/Documentation/index.vue';
import HealthSecurityDocumentationPage from '@/pages/HealthAndSecurity/Documentation/index.vue';

// Details pages
import AgroDetailsPage from '@/pages/Agronomy/AgronomyDetails/agro-details-page.vue';
import FoodSampleDetailPage from '@/pages/Food/FoodSampleDetailPage/index.vue';
import EnvironmentSamplesDetailsPage from '@/pages/Environment/SamplesDetailsPage/index.vue';
import MiningSamplesDetailsPage from '@/pages/Mining/SamplesDetailsPage/index.vue';
import HealthSecuritySamplesDetailsPage from '@/pages/HealthAndSecurity/SamplesDetailsPage/index.vue';

// Alerts
import AlertsParamsPage from '@/pages/Alerts/Settings/params/index.vue';
import AlertsSamplePage from '@/pages/Alerts/Settings/sample/index.vue';
import AlertsParamsReportsPage from '@/pages/Alerts/Reports/params/index.vue';
import AlertsSampleReportsPage from '@/pages/Alerts/Reports/sample/index.vue';

// StatsQueries
import PesticideResidues from '@/pages/StatsQueries/ResiduosPlaguicidas/index.vue';

// Notifications
import NotificationsDetailsPage from '@/pages/Notifications/index.vue';

// Privacy Policy
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage.vue';

// OffersList
import OffersList from '@/pages/OffersList/index.vue';
import OffersDetails from '@/pages/OffersList/OffersDetails/offers-details-page.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/StatsQueries/',
    name: 'PesticideResidues',
    component: PesticideResidues,
  },
  {
    path: '/OffersList/',
    name: 'offersList',
    component: OffersList,
  },
  {
    path: '/OffersDetails/',
    name: 'offersDetails',
    component: OffersDetails,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/users',
    name: 'UsersManagementPage',
    component: UsersManagementPage,
  },
  {
    path: '/users/create',
    component: UsersCreatePage,
  },
  {
    path: '/users/edit',
    name: 'UsersEditPage',
    component: UsersEditPage,
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/agronomy/samples',
    name: 'AgronomySamplesPage',
    component: AgronomySamplesPage,
  },
  {
    path: '/agronomy/nutritionalMonitoring',
    name: 'NutritionalMonitoringMainPage',
    component: NutritionalMonitoringPage,
  },
  {
    path: '/agronomy/advanced-services',
    name: 'AdvancedServicesPage',
    component: AdvancedServicesPage,
  },
  {
    path: '/agronomy/advanced-services/dashboard',
    name: 'DashboardPage',
    component: DashboardPage,
  },
  {
    path: '/agronomy/advanced-services/nutritional-monitoring',
    name: 'NutritionalMonitoringPage',
    component: NutritionalMonitoring,
  },
  {
    path: '/agronomy/advanced-services/management',
    name: 'AdvancedServicesManagementPage',
    component: AdvancedServicesManagementPage,
  },
  {
    path: '/agronomy/advanced-services/management/parcel-registration',
    name: 'AdvancedServicesParcelRegistrationPage',
    component: AdvancedServicesParcelRegistrationPage,
  },
  {
    path: '/agronomy/advanced-services/management/station-registration',
    name: 'AdvancedServicesStationRegistrationPage',
    component: AdvancedServicesStationRegistrationPage,
  },
  {
    path: '/agronomy/gis-actions',
    name: 'GisActionsPage',
    component: GisActionsPage,
  },
  {
    path: '/agronomy/samples/details',
    name: 'AgronomySamplesDetailsPage',
    component: AgroDetailsPage,
  },
  {
    path: '/agronomy/documentation',
    name: 'AgronomyDocumentationPage',
    component: AgronomyDocumentationPage,
  },
  {
    path: '/environment/samples',
    name: 'EnvironmentSamplesPage',
    component: EnvironmentSamplesPage,
  },
  {
    path: '/environment/samples/details',
    name: 'EnvironmentSamplesDetailsPage',
    component: EnvironmentSamplesDetailsPage,
  },
  {
    path: '/environment/documentation',
    name: 'EnvironmentDocumentationPage',
    component: EnvironmentDocumentationPage,
  },
  {
    path: '/environment/stats',
    name: 'EnvironmentStatsPage',
    component: EnvironmentStatsPage,
  },
  {
    path: '/food/risk-analysis',
    name: 'RiskAnalysisPage',
    component: RiskAnalysisPage,
  },
  {
    path: '/food/stats',
    name: 'FoodStatsPage',
    component: FoodStatsPage,
  },
  {
    path: '/food/samples',
    name: 'FoodSamplesPage',
    component: FoodSamplesPage,
  },
  {
    path: '/food/samples/details',
    name: 'FoodSampleDetailPage',
    component: FoodSampleDetailPage,
  },
  {
    path: '/food/documentation',
    name: 'FoodDocumentationPage',
    component: FoodDocumentationPage,
  },
  {
    path: '/mining/samples',
    name: 'MiningSamplesPage',
    component: MiningSamplesPage,
  },
  {
    path: '/mining/samples/details',
    name: 'MiningSamplesDetailsPage',
    component: MiningSamplesDetailsPage,
  },
  {
    path: '/mining/documentation',
    name: 'MiningDocumentationPage',
    component: MiningDocumentationPage,
  },
  {
    path: '/health-and-security/samples',
    name: 'HealthAndSecuritySamplesPage',
    component: HealthAndSecuritySamplesPage,
  },
  {
    path: '/health-and-security/samples/details',
    name: 'HealthSecuritySamplesDetailsPage',
    component: HealthSecuritySamplesDetailsPage,
  },
  {
    path: '/health-and-security/documentation',
    name: 'HealthSecurityDocumentationPage',
    component: HealthSecurityDocumentationPage,
  },
  {
    path: '/alerts/params',
    name: 'AlertsParamsPage',
    component: AlertsParamsPage,
  },
  {
    path: '/alerts/params/reports',
    name: 'AlertsParamsReportsPage',
    component: AlertsParamsReportsPage,
  },
  {
    path: '/alerts/sample',
    name: 'AlertsSamplePage',
    component: AlertsSamplePage,
  },
  {
    path: '/alerts/sample/reports',
    name: 'AlertsSampleReportsPage',
    component: AlertsSampleReportsPage,
  },
  {
    path: '/notifications',
    name: 'NotificationsDetailsPage',
    component: NotificationsDetailsPage,
  },
  {
    path: '/token-recovery',
    name: 'TokenRecoveryPage',
    component: TokenRecoveryPage,
  },
  {
    path: '/advanced-services/weather-graphs',
    name: 'WeatherGraphsPage',
    component: WeatherGraphsPage,
  },
  {
    path: '/gis/parcel-detail',
    name: 'TimeDimensionMapPage',
    component: TimeDimensionMapPage,
  },
  {
    path: '/gis/comparator',
    name: 'GisComparatorPage',
    component: GisComparatorPage,
  },
  {
    path: '/gis/parcel-detail-comparator',
    name: 'TimeDimensionComparatorPage',
    component: TimeDimensionComparatorPage,
  },
  {
    path: '/agronomy/advanced-services/mir-detail',
    name: 'MIRDetail',
    component: MIRDetail,
  },
  {
    path: '/agronomy/advanced-services/mir-stations',
    name: 'MIRStationsPage',
    component: MIRStationsPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
