import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {
    enabled: true,
    address: '',
    addressName: '',
    company: '',
    companyName: '',
    showNewContactPopup: false,
    addresses: [],
    contactIndex: 0,
    hasAccessToAdvanceService: false,
    hasAccessToNutritionalMonitoring: false,
  },
  getters: {
    contactInfo(state) {
      var filteredAddresses = state.addresses?.filter(
        (x) => x.addressCode == state.address
      );

      return {
        address: state.address,
        addressName: state.addressName,
        company: state.company,
        companyName: state.companyName,
        country: filteredAddresses[0].country,
        city: filteredAddresses[0].city,
        province: filteredAddresses[0].province,
        desc_comuna: filteredAddresses[0].desc_comuna,
        fax: filteredAddresses[0].fax,
        phone: filteredAddresses[0].phone,
        identification_number:
          filteredAddresses[0].identification_number,
        currency: filteredAddresses[0].currency,
        language: filteredAddresses[0].language,
        addresses: state.addresses,
        adressesCity: state.addresses.map((address) => ({
          ...address,
          name: `${address.city} - ${address.name}`,
        })),
        agent: filteredAddresses[0].agent,
      };
    },
  },
  actions: {
    setEnabled({ commit }, value) {
      commit('setEnabled', value);
    },
    setAddressName({ commit }, value) {
      commit('setAddressName', value);
    },
    setAddress({ commit }, value) {
      commit('setAddress', value);
    },
    setCompany({ commit }, value) {
      commit('setCompany', value);
    },
    setCompanyName({ commit }, value) {
      commit('setCompanyName', value);
    },
    setShowNewContactPopup({ commit }, value) {
      commit('setShowNewContactPopup', value);
    },
    setAddresses({ commit }, value) {
      commit('setAddresses', value);
    },
    setContactIndex({ commit }, value) {
      commit('setContactIndex', value);
    },
    async edit({ rootState }, data) {
      try {
        await Api.updateContact(
          {
            name: data.name,
            surname: data.surname,
            companyId: data.companyId,
            addresses: data.addresses,
            phone: data.phone,
          },
          data.contactCode,
          rootState.contact.company,
          rootState.contact.address
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async create({ rootState }, data) {
      try {
        const res = await Api.createContact(
          {
            name: data.name,
            surname: data.surname,
            email: data.email,
            companyId: data.companyId,
            addresses: data.addresses,
            phone: data.phone,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        if (res.status === 400) {
          const alreadyExists = await res.json();

          return Promise.reject(
            new Error(
              alreadyExists.error.includes('already exists')
                ? 'mailAlreadyExists'
                : undefined
            )
          );
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async checkAccessToAdvanceService({ commit, rootState }) {
      try {
        const xhr = await Api.checkAccessToAdvancedServices(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        commit(
          'setHasAccessToAdvanceService',
          response?.access_to_advance_services ?? false
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async checkAccessToNutritionalMonitoring({ commit, rootState }) {
      try {
        const xhr = await Api.getNutritionalMonitoringWithoutGeometry(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        commit(
          'setHasAccessToNutritionalMonitoring',
          response?.data?.length > 0 ?? false
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    resetData({ commit }) {
      commit('resetData');
    },
  },
  mutations: {
    setEnabled(state, value) {
      state.enabled = value;
    },
    setAddressName(state, value) {
      state.addressName = value;
    },
    setAddress(state, value) {
      state.address = value;
    },
    setCompany(state, value) {
      state.company = value;
    },
    setCompanyName(state, value) {
      state.companyName = value;
    },
    setShowNewContactPopup(state, value) {
      state.showNewContactPopup = value;
    },
    setAddresses(state, value) {
      state.addresses = value;
    },
    setContactIndex(state, value) {
      state.contactIndex = value;
    },
    setHasAccessToAdvanceService(state, value) {
      state.hasAccessToAdvanceService = value;
    },
    setHasAccessToNutritionalMonitoring(state, value) {
      state.hasAccessToNutritionalMonitoring = value;
    },
    resetData(state) {
      state.enabled = true;
      state.address = '';
      state.addressName = '';
      state.company = '';
      state.companyName = '';
      state.showNewContactPopup = false;
      state.addresses = [];
      state.contactIndex = 0;
      state.hasAccessToAdvanceService = false;
      state.hasAccessToNutritionalMonitoring = false;
    },
  },
};
