<template>
  <div>
    <div v-if="loading" class="tableContainer">
      <div class="item-title-bold no-margin no-padding"></div>
      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ this.$t('offerCode') }}</div>
              <div class="item-title-bold">
                <div>
                  {{ OfferSummary.CodPresupuesto }}
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ this.$t('version') }}</div>
              <div class="item-title-bold">
                {{ OfferSummary.Version }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ this.$t('clientCode') }}</div>
              <div class="item-title-bold">
                {{ OfferSummaryV2.CodCliente }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ this.$t('agronomySample.summary.client') }}
              </div>
              <div class="item-title-bold">
                {{ OfferSummaryV2.RazonSocial }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ this.$t('address') }}
              </div>
              <div class="item-title-bold">
                {{ OfferSummaryV3.CodPostal }},
                {{ OfferSummaryV3.Ciudad }},
                {{ OfferSummaryV3.LineaDireccion1 }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>NIF</div>
              <div class="item-title-bold">
                {{ OfferSummaryV2.IdentificacionFiscal }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ this.$t('contacts') }}</div>
              <div class="item-title-bold">
                {{ OfferSummaryV4.Email }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ this.$t('phone') }}</div>
              <div class="item-title-bold">
                {{ OfferSummaryV3.Telefono }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'OfferListSummary',
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('Offers', [
      'detallesPresupuestosSummary',
      'detallesClientePresupuesto',
    ]),
    OfferSummary() {
      return this.detallesPresupuestosSummary;
    },
    OfferSummaryV2() {
      return this.detallesClientePresupuesto;
    },
    OfferSummaryV3() {
      if (
        this.detallesPresupuestosSummary &&
        this.detallesClientePresupuesto
      ) {
        const codAddress =
          this.detallesPresupuestosSummary.CodDireccion;
        const foundAddress =
          this.detallesClientePresupuesto.Direcciones.find(
            (address) => address.CodDireccion === codAddress
          );
        return foundAddress || 'Not found';
      }
      return 'Not found';
    },
    OfferSummaryV4() {
      if (
        this.detallesPresupuestosSummary &&
        this.detallesClientePresupuesto
      ) {
        const codContact =
          this.detallesPresupuestosSummary.CodContacto;
        const foundContact =
          this.detallesClientePresupuesto.Contactos.find(
            (contact) => contact.CodContacto === codContact
          );
        return foundContact || 'Not found';
      }
      return 'Not found';
    },
  },
  async mounted() {
    try {
      await this.fetchDetallesPresupuestosSummary();
      this.loading = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(error.message)
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('Offers', ['fetchDetallesPresupuestosSummary']),
  },
};
</script>
<style scoped></style>
