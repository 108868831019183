<template>
  <div class="tableContainer">
    <div id="report-container"></div>
  </div>
</template>

<script>
import * as pbi from 'powerbi-client';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('contact', ['contactInfo']),
    ...mapState('contact', ['company']),
    ...mapState('PowerBiModules', ['insertionToken', 'reportId']),
  },
  mounted() {
    if (this.insertionToken && this.reportId) {
      this.embedReport();
    }
  },
  methods: {
    async embedReport() {
      if (!this.insertionToken || !this.reportId) {
        console.error('Missing Token or Report ID.');
        return;
      }

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const embedContainer = document.getElementById(
        'report-container'
      );
      powerbi.reset(embedContainer);

      const config = this.getEmbedConfig();

      try {
        powerbi.embed(embedContainer, config);
      } catch (error) {
        console.error('Error embedding the report:', error);
      }
    },
    getEmbedConfig() {
      return {
        type: 'report',
        accessToken: this.insertionToken,
        embedUrl: 'https://app.powerbi.com/reportEmbed?',
        id: this.reportId,
        permissions: pbi.models.Permissions.Read,
        tokenType: pbi.models.TokenType.Embed,
        settings: {
          panes: {
            bookmarks: {
              visible: false,
            },
            fields: {
              expanded: false,
            },
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: true,
            },
            selection: {
              visible: true,
            },
            syncSlicers: {
              visible: true,
            },
            visualizations: {
              expanded: false,
            },
          },
          bars: {
            actionBar: {
              visible: false,
            },
            statusBar: {
              visible: false,
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../PowerBIEmbedded/informecontainer.styles.scss';
</style>
