<template>
  <div>
    <div
      class="filterButtonPosition"
      style="display: flex; align-items: center; gap: 0.4rem"
    >
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextSummaryOffers')"
      @cell-prepared="onCellPrepared"
      @content-ready="init"
    >
      <DxScrolling mode="standard" />

      <DxStateStoring
        :enabled="true"
        type="custom"
        storage-key="OffersTable"
        :custom-load="loadState"
        :custom-save="customSave"
      />

      <DxPaging :page-size="10" />

      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('searchOfferCode')"
      />

      <DxColumn
        data-field="CodPresupuesto"
        :caption="$t('offerCode')"
        :width="150"
        :allow-sorting="true"
        :fixed="true"
        fixed-position="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Titulo"
        :caption="$t('title')"
        :width="300"
        :allow-sorting="true"
        :fixed="true"
        fixed-position="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="SubTitulo"
        :caption="$t('subTitle')"
        :width="100"
        :allow-sorting="true"
        :fixed="true"
        fixed-position="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="FechaPresupuesto"
        :caption="$t('initialDate')"
        :width="100"
        :allow-sorting="true"
        :fixed="true"
        fixed-position="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="CodEstado"
        :caption="$t('status')"
        :width="100"
        :fixed="true"
        alignment="left"
        fixed-position="right"
        :allow-header-filtering="false"
        cell-template="cellTemplateStatus"
      />

      <DxColumn
        :buttons="updatedEditButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="80"
        name="actionColumn"
      />

      <template #cellTemplateStatus="{ data }">
        <div
          :class="$helpers.getStatusClass(data.data.CodEstado)"
          style="text-align: center"
        >
          {{ data.data.Estado }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';
import { mapActions } from 'vuex';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
  },
  props: {
    store: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
      ],
    };
  },
  computed: {
    updatedEditButtons() {
      return [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
      ];
    },
  },
  methods: {
    loadState() {
      let state = localStorage.getItem('OffersTable');
      if (state) {
        state = JSON.parse(state);
        state.searchText = '';
      }
      return state;
    },
    customSave(state) {
      localStorage.setItem('OffersTable', JSON.stringify(state));
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        // Verificar si la columna actual es 'CodPresupuesto'
        if (e.column.dataField === 'CodPresupuesto') {
          if (e.cellElement.textContent.substring(0, 3) === 'QCF') {
            // Guardar la condición para usarla luego
            e.data.isQCF = true;
          } else {
            e.data.isQCF = false;
          }
        }
        // Verificar si la columna actual es 'actionColumn'
        if (e.column.name === 'actionColumn') {
          // Solo hacer display = 'none' si CodPresupuesto comienza con 'QCF'
          // let accessNotAvailable = "$t('accessNotAvailable')";
          if (e.data.isQCF) {
            e.cellElement.innerText = ' ';
          }
        }
      }
    },
    init(e) {
      this.grid = e;
    },
    viewAtc(e) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(false);
      this.setLastRouteGeotoolboxSamples(false);
      this.fetchDetallesPresupuestos(e.row.data.CodPresupuesto).catch(
        (error) => {
          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(error)
              ),
              width: 550,
            },
            'error'
          );
        }
      );

      this.replaceCurrent({
        name: this.$t('offersDetails'),
        disabled: false,
        page: 'offersDetails',
        active: true,
      });

      this.$f7router.navigate(
        { name: 'offersDetails' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    ...mapActions('Offers', ['fetchDetallesPresupuestos']),
    ...mapActions('breadcrumb', ['replaceCurrent']),
    ...mapActions('samples', [
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
  },
};
</script>

<style scoped>
.data-grid-container {
  height: 400px;
}
</style>
