<template>
  <div class="margin-top">
    <div class="label-input margin-top-half">
      {{ $t('RequestDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('RequestDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedRequestDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setRequestDate"
      />
    </div>
    <div class="marginTop15">
      <div>{{ $t('status') }}</div>

      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('status')"
        tag-template="tag"
        :value="selectedStatuses"
        display-expr="Estado"
        value-expr="Estado"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.Estado }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <!-- Este va a ser el tipo de entrega-->
    <div class="marginTop15">
      <div>
        {{ $t('deliveryType') }}
      </div>
      <DxTagBox
        :data-source="deliveryTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('deliveryType')"
        tag-template="tag"
        :value="selectedDeliveryTypes"
        display-expr="label"
        value-expr="id"
        :on-value-changed="deliveryTypesChanged"
        :on-opened="openedDeliveryTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.label }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import { AFTER_DATE_LIMIT } from '@/js/constants';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';

export default {
  components: {
    DxTagBox,
  },
  data() {
    return {
      statuses: [],
      deliveryTypes: [
        { id: 1, label: 'Recogida' },
        { id: 2, label: 'Entrega' },
      ],
    };
  },

  computed: {
    calculatedRequestDate() {
      if (
        this.requestFromDate !== null &&
        this.requestToDate !== null
      ) {
        return [this.requestFromDate, this.requestToDate];
      }
      return [];
    },
    selectedStatuses: {
      get() {
        return this.$store.state.materialRequestFilters
          .selectedStatuses;
      },
      set(value) {
        this.setSelectedStatuses(value);
      },
    },
    selectedDeliveryTypes: {
      get() {
        return this.$store.state.materialRequestFilters.tipoEnvio;
      },
      set(value) {
        this.setTipoEnvio(value);
      },
    },
    ...mapState('materialRequestFilters', [
      'requestToDate',
      'requestFromDate',
    ]),
    ...mapState('materialRequest', ['solicitudesMateriales']),
  },

  mounted() {},

  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    setRequestDate(e) {
      if (e.length === 2) {
        this.setRequestFromDate(e[0]);
        this.setRequestToDate(e[1]);
      } else if (e.length === 0) {
        this.setRequestFromDate(null);
        this.setRequestToDate(null);
      }
    },
    async openedStatuses() {
      const statuses = this.extractStatusesFromSolicitudesMateriales(
        this.solicitudesMateriales
      );
      this.statuses = new DataSource({
        store: statuses,
        key: 'Estado',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedDeliveryTypes() {
      const types =
        this.extractDeliveryTypesFromSolicitudesMateriales(
          this.solicitudesMateriales
        );
      this.deliveryTypes = new DataSource({
        store: types,
        key: 'label',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    extractDeliveryTypesFromSolicitudesMateriales(DeliveryTypes) {
      const deliveryTypesSet = new Set();
      DeliveryTypes.forEach((solicitudesMateriales) => {
        if (
          solicitudesMateriales.TipoEntrega &&
          solicitudesMateriales.TipoEntregaDescrp
        ) {
          deliveryTypesSet.add(
            JSON.stringify({
              id: solicitudesMateriales.TipoEntrega,
              label: solicitudesMateriales.TipoEntregaDescrp,
            })
          );
        }
      });
      return Array.from(deliveryTypesSet).map((item) =>
        JSON.parse(item)
      );
    },
    extractStatusesFromSolicitudesMateriales(SolicitudesMateriales) {
      const statusSet = new Set();
      SolicitudesMateriales.forEach((solicitudesMateriales) => {
        if (
          solicitudesMateriales.Estado &&
          solicitudesMateriales.EstadoDescrp
        ) {
          statusSet.add(
            JSON.stringify({
              CodEstado: solicitudesMateriales.Estado,
              Estado: solicitudesMateriales.EstadoDescrp,
            })
          );
        }
      });
      return Array.from(statusSet).map((item) => JSON.parse(item));
    },
    statusesChanged(e) {
      const SelectedStatuses = e.value;
      this.setSelectedStatuses(SelectedStatuses);
    },
    deliveryTypesChanged(e) {
      const selectedDeliveryTypes = e.value;
      this.setTipoEnvio(selectedDeliveryTypes);
    },
    contentReady() {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });
    },
    ...mapActions('materialRequestFilters', [
      'setRequestToDate',
      'setRequestFromDate',
      'setSelectedStatuses',
      'setTipoEnvio',
    ]),
  },
};
</script>
