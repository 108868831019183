<template>
  <div>
    <div
      class="filterButtonPosition"
      style="display: flex; align-items: center; gap: 0.4rem"
    >
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
      <ContextMenu :id="'context-menu-btn'" :items="getItems" />
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @cell-prepared="onCellPrepared"
      @content-ready="init"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="custom"
        storage-key="agronomySamplesTable"
        :custom-load="loadState"
        :custom-save="customSave"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('searchSampleCode')"
      />
      <DxExport :enabled="false" />
      <DxColumn
        data-field="code"
        :caption="$t('agronomySample.summary.sampleCode')"
        alignment="left"
        :allow-header-filtering="false"
        :fixed="true"
        fixed-position="left"
      />
      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sampling_date"
        :caption="$t('agronomySample.summary.samplingDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="reception_date"
        :caption="$t('foodSamples.receptionDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="farm"
        :caption="$t('agronomySample.summary.estate')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('agronomySample.summary.parcel')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_name"
        :caption="$t('agronomySample.summary.description')"
        :width="300"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="cultive"
        :caption="$t('agronomySample.cultive')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="status"
        :caption="$t('status')"
        alignment="left"
        :width="150"
        :fixed="true"
        fixed-position="right"
        :allow-header-filtering="false"
        cell-template="cellTemplateStatus"
      />
      <template #cellTemplateStatus="{ data }">
        <div
          :class="$helpers.getStatusClass(data.data.status_code)"
          style="text-align: center"
        >
          {{ data.data.status }}
        </div>
      </template>
      <!--<DxColumn
        data-field="type_service_code"
        :caption="$t('service')"
        alignment="left"
        :allow-header-filtering="false"
      />-->
      <DxColumn
        data-field="study"
        :caption="$t('project')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="analyse_type"
        :caption="$t('foodSamples.analysisType')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        :buttons="updatedEditButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="80"
      />
    </DxDataGrid>

    <!-- <ContextMenu :id="'context-menu-btn'" :items="getItems" /> -->

    <NotificationSpinner
      v-if="notificationMessage.length > 0"
      :message="notificationMessage"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ContextMenu from '@/components/contextMenu/index.vue';
import {
  ALLOWED_SAMPLES_PARAMETERS,
  ATCS_ES,
  NOTIFY_TIME_LONG,
} from '@/js/constants';
import NotificationSpinner from '@/components/NotificationSpinner';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    ContextMenu,
    NotificationSpinner,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
        {
          icon: 'download',
          onClick: this.download,
        },
      ],
      itemsDisabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'excel',
          text: this.$t('excelDownloadParameters'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
        {
          icon: 'find',
          onClick: null,
          disabled: true,
        },
        {
          icon: 'download',
          onClick: null,
          disabled: true,
        },
      ],
      itemsFilter: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
        {
          icon: 'excel',
          text: this.$t('excelDownloadParameters'),
          onItemClick: async () => {
            try {
              let message = this.$t('downloading');
              if (this.grid.component.totalCount() > ALLOWED_SAMPLES_PARAMETERS) {
                message = this.$t('sampleParametersMustFilter');
              }
              this.notificationMessage = message;
              const samples = await this.getSamplesWithParameters(
                this.disableFilterMonitoring
              );
              this.formatExcel(samples);
            } catch (e) {
              this.notificationMessage = '';

              this.$notifyDX(
                {
                  message: this.$t('downloadError'),
                  width: 350,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
            }
          },
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: () => {
            // this.onExporting(this.grid);
          },
          disabled: true,
        },
      ],
      itemsEnabled: [
        {
          icon: 'excel',
          text: this.$t('excelDownload'),
          onItemClick: () => {
            this.onExporting(this.grid);
          },
        },
        {
          icon: 'excel',
          text: this.$t('excelDownloadParameters'),
          onItemClick: async () => {
            try {
              this.setDisableContext(true);
              let message = this.$t('downloading');
              if (this.grid.component.totalCount() > ALLOWED_SAMPLES_PARAMETERS) {
                message = this.$t('sampleParametersMustFilter');
              }
              this.notificationMessage = message;

              const samples = await this.getSamplesWithParameters(
              );
              this.formatExcel(samples);
            } catch (e) {
              this.notificationMessage = '';

              this.$notifyDX(
                {
                  message: this.$t('downloadError'),
                  width: 350,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setDisableContext(false);
            }
          },
        },
        {
          icon: 'pdf',
          text: this.$t('pdfReportDownload'),
          onItemClick: async () => {
            this.setDisableContext(true);

            try {
              this.notificationMessage = this.$t('downloading');
              const b64 = await this.getSamplesZip();

              const link = document.createElement('a');
              link.href = `data:application/octet-stream;base64,${b64}`;
              link.download = `besafer-reports-${new Date().toLocaleString()}.zip`;
              link.click();

              URL.revokeObjectURL(link.href);

              this.notificationMessage = this.$t('downloadedFile');
            } catch (e) {
              this.notificationMessage = '';
              this.$notifyDX(
                {
                  message: this.$t(
                    this.$helpers.getFilteredErrorMessage(
                      JSON.parse(e.message).error
                    )
                  ),
                  width: 550,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } finally {
              setTimeout(() => {
                this.notificationMessage = '';
              }, 2000);
              this.setDisableContext(false);
            }
          },
        },
      ],
      grid: null,
      notificationMessage: '',
    };
  },
  computed: {
    agronomySamples() {
      return this.filteredSamples !== null
        ? this.filteredSamples
        : this.allSamples;
    },
    getItems() {
      if (this.disableFilterMonitoring) {
        return this.disableFilterMonitoring
          ? this.itemsFilter
          : this.itemsEnabled;
      } else {
        return this.disableContext
          ? this.itemsDisabled
          : this.itemsEnabled;
      }
    },
    ...mapState('agronomySamples', ['allSamples', 'filteredSamples']),
    ...mapState('samples', ['disableContext', 'isDownloadingReport']),
    ...mapState('filter', ['disableFilterMonitoring']),
    ...mapState('agronomyFilter', ['selectedMonitoring']),
    updatedEditButtons() {
      if (this.disableFilterMonitoring) {
        return [
          {
            icon: 'find',
            onClick: null,
            disabled: true,
          },
          {
            icon: 'download',
            onClick: null,
            disabled: true,
          },
        ];
      } else {
      return this.isDownloadingReport
        ? [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.download,
              disabled: true,
            },
          ]
        : [
            {
              icon: 'find',
              onClick: this.viewAtc,
            },
            {
              icon: 'download',
              onClick: this.download,
              disabled: this.isDisabled,
            },
          ];
      }
    },
  },
  beforeMount() {
    
    this.setAllFilterParams({ atc_name: ATCS_ES.agronomy});
    this.setDisableContext(false)
    this.setDisableFilterMonitoring(this.disableFilterMonitoring || false);
  },
  
  watch: {
    disableFilterMonitoring(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setDisableFilterMonitoring(newVal);
      }
    }
  },
  methods: {
    loadState() {
      let state = localStorage.getItem('agronomySamplesTable');
      if (state) {
        state = JSON.parse(state);
        state.searchText = '';
      }
      return state;
    },
    customSave(state) {
      localStorage.setItem(
        'agronomySamplesTable',
        JSON.stringify(state)
      );
    },
    isDisabled(e) {
      return e?.row?.data?.status_code !== '3';
    },
    formatExcel(samples) {
      if (samples.length > ALLOWED_SAMPLES_PARAMETERS) {
        this.notificationMessage = this.$t(
          'sampleParametersMustFilter'
        );
        this.setDisableContext(false);

        return;
      }

      const config = [
        {
          sheet: 'Samples',
          columns: [
            {
              label: this.$t('agronomySample.summary.sampleCode'),
              value: 'code',
            },
            {
              label: this.$t('agronomySample.summary.sampleType'),
              value: 'sample_type',
            },
            {
              label: this.$t('agronomySample.summary.samplingDate'),
              value: 'sampling_date',
            },
            {
              label: this.$t('foodSamples.receptionDate'),
              value: 'reception_date',
            },
            {
              label: this.$t('agronomySample.summary.estate'),
              value: 'farm',
            },
            {
              label: this.$t('agronomySample.summary.parcel'),
              value: 'parcel',
            },
            {
              label: this.$t('agronomySample.summary.description'),
              value: 'sample_name',
            },
            {
              label: this.$t('agronomySample.summary.lot_code'),
              value: 'lot_code',
            },
            {
              label: this.$t('agronomySample.cultive'),
              value: 'cultive',
            },
            {
              label: this.$t('project'),
              value: 'study',
            },
            {
              label: this.$t('foodSamples.analysisType'),
              value: 'analyse_type',
            },
            {
              label: this.$t('status'),
              value: 'status',
            },
          ],
          content: [
            ...samples.map((sample) => {
              sample.parameters.forEach((parameter) => {
                sample.sample[
                  `${parameter.parameter} ${parameter.unit}`
                ] = parameter.value;
              });

              return sample.sample;
            }),
          ],
        },
      ];

      samples.forEach((sample) => {
        sample.parameters.forEach((parameter) => {
          config[0].columns.push({
            label: `${parameter.parameter} ${parameter.unit}`,
            // value: `${parameter.parameter} ${parameter.unit}`,
            value: (data) =>
              data[`${parameter.parameter} ${parameter.unit}`],
          });
        });
      });

      const fileSettings = {
        fileName: `besafer-samples-parameters-${this.$helpers.getDateFormatedDayMonthYear(
          new Date()
        )}`,
        extraLength: 3,
        writeOptions: {},
      };

      this.$jsonToXLSX(config, fileSettings, () => {
        this.setDisableContext(false);

        this.notificationMessage = this.$t(
          'xslxDownloadedSuccessfully'
        );
      });
    },
    init(e) {
      this.setSamples(e.component.getDataSource().items());
      this.grid = e;
    },
    onToolbarPreparing(e) {
      // e.toolbarOptions.items.unshift({
      //   location: 'after',
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'chevrondown',
      //     elementAttr: {
      //       id: 'context-menu-btn',
      //     },
      //     onClick: ({ event }) => {
      //       event.preventDefault();
      //     },
      //   },
      // });
    },
    async download(e) {
      try {
        this.setIsDownloadingReport(true);
        this.setReportSampleId(e.row.data.sample_id);

        this.notificationMessage = this.$t('downloading');

        const report = await this.getReport();

        if (report.name && report.pdfB64) {
          const link = document.createElement('a');
          link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
          link.download = report.name;
          link.click();

          URL.revokeObjectURL(link.href);
          this.notificationMessage = this.$t('downloadedFile');
        } else {
          this.notificationMessage = '';
          this.$notifyDX(
            {
              message: this.$t('sampleHasNoReport'),
              width: 450,
            },
            'info',
            2000
          );
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('reportNotAvailable'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        setTimeout(() => {
          this.notificationMessage = '';
        }, 2000);
        this.setIsDownloadingReport(false);
      }
    },
    viewAtc(e) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(false);
      this.setLastRouteGeotoolboxSamples(false);
      
      this.setCurrentSampleId(e.row.data.id);
      this.setReportSampleId(e.row.data.sample_id);
      this.setCurrentSample(e.row.data);
      this.setReportSampleCode(e.row.data.code);

      this.replaceCurrent({
        name: this.$t('sampleSummary'),
        disabled: true,
        page: 'AgronomySamplesDetailsPage',
        active: true,
      });

      this.$f7router.navigate(
        { name: 'AgronomySamplesDetailsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.exportExcelSampleTable'
        )}-${this.$t('Agronomy')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-samples-${this.$helpers.getDateFormatedDayMonthYear(
                new Date()
              )}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (!e.data.seen) {
          e.cellElement.classList.add('hasntBeenSeen');
        }
      }
    },
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
      'setDisableFilterMonitoring',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'setCurrentSample',
      'setReportSampleCode',
      'setDisableContext',
      'getSamplesWithParameters',
      'setAllFilterParams',
      'getSamplesZip',
      'setIsDownloadingReport',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('agronomySamples', ['setSamples']),
    ...mapActions('breadcrumb', ['replaceCurrent']),
  },
};
</script>
