<template>
  <div>
    <DxPopup
      v-if="loaded"
      :visible="showDetailsPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      width="900"
      height="auto"
      container=".dx-viewport"
      :title="title"
      @hiding="hide"
    >
      <div class="tableContainer">
        <DxDataGrid
          :data-source="store"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          :show-borders="true"
          :show-row-lines="true"
          :no-data-text="$t('noDataTextSummaryOffers')"
          @cell-prepared="onCellPrepared"
        >
          <DxColumn
            :caption="$t('TechnicalParameter')"
            data-field="ParametroTecnica"
          />
          <DxColumn
            :caption="$t('Subcontracted')"
            alignment="center"
            data-field="Subcontratado"
          />

          <DxColumn
            :caption="$t('Technique')"
            alignment="center"
            data-field="Tecnica"
          />
          <DxColumn
            :caption="$t('PNT')"
            alignment="center"
            data-field="NombrePNT"
          />
          <DxColumn
            :caption="$t('MinimumValue')"
            alignment="center"
            data-field="ValorMinimo"
          />
          <DxColumn
            :caption="$t('MaximumValue')"
            alignment="center"
            data-field="ValorMaximo"
          />
          <DxColumn
            :caption="$t('UnitMeasure')"
            alignment="center"
            data-field="UnidadMedida"
          />
          <DxColumn
            :caption="$t('AccreditationInfo')"
            alignment="center"
            data-field="Acreditaciones"
          />
          <DxPager
            :allowed-page-sizes="[5, 10, 15, 20]"
            :show-page-size-selector="true"
            info-text="{2} items"
            :visible="true"
          />
          <DxPaging :page-size="5" />
        </DxDataGrid>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxPopup,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
  },
  props: {
    store: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      subcontratado: '',
      loaded: false,
    };
  },
  computed: {
    ...mapState('Offers', {
      showDetailsPopup: 'showDetailsPopup',
      detallesPresupuestos: 'detallesPresupuestos',
      title: 'popupTitle',
    }),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error'
      );
    } finally {
      setTimeout(() => {
        this.$f7.preloader.hide();
      }, 4000);
      this.loaded = true;
    }
  },
  methods: {
    onCellPrepared(e) {
      if (
        e.rowType === 'data' &&
        e.column.dataField === 'Subcontratado'
      ) {
        const subcontratadoValue = e.data.Subcontratado ? 'Sí' : 'No';
        e.cellElement.innerText = subcontratadoValue;
      }
    },
    hide() {
      this.setShowDetailsPopup(false);
    },
    ...mapActions('Offers', ['setShowDetailsPopup']),
  },
};
</script>
