<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <OfferListTable :store="customStore" />
      </div>
    </div>
  </f7-page>
</template>
<script>
import OfferListTable from '@/components/OffersList/OfferListTable.vue';
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: { OfferListTable },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  computed: {
    ...mapState('contact', ['company', 'addresses']),
    ...mapState('Offers', ['presupuestos']),
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('My_offers'),
        disabled: false,
        page: 'offersList',
        active: true,
      },
    ]);
    this.setCurrentFilter('offersList');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('offersList', async (estado) => {
        if (estado) {
          await this.fetchPresupuestos(estado);
          this.customStore = this.presupuestos;
        } else {
          await this.fetchPresupuestos();
          this.customStore = this.presupuestos.filter(
            (presupuesto) => {
              return (
                presupuesto.Estado === 'Pendiente' ||
                presupuesto.Estado === 'Aceptado'
              );
            }
          );
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(error.message)
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('offersList');
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('Offers', ['fetchPresupuestos']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
    ...mapActions('OffersListFilters', ['setSelectedStatuses']),
  },
};
</script>
