<template>
  <f7-panel
    left
    :visible-breakpoint="breakpoint"
    class="panel panel-left not-animated content-menu"
    style="z-index: 6000"
  >
    <div class="left-panel__header padding-horizontal">
      <img class="left-panel__logo" :src="APP_LOGO" />
    </div>
    <f7-list
      media-list
      class="list no-chevron no-hairlines no-hairlines-between no-margin-bottom"
    >
      <ul>
        <f7-list-item
          class="item-menu-home"
          link="/"
          :title="$t('Home')"
          transition="f7-fade"
          @click="itemClick"
        >
          <f7-icon
            slot="media"
            class="accordion__icon accordion__icon--home item-media"
          >
          </f7-icon>
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-list
      accordion-list
      class="list no-hairlines no-hairlines-between no-margin-top"
    >
      <ul>
        <f7-list-item
          v-for="(accordionItem, panelOptionsIndex) in panelOptions"
          :key="`${accordionItem.name}_${panelOptionsIndex}`"
          accordion-item
          style="padding: 5px 0px"
          :title="$t(accordionItem.name)"
        >
          <f7-icon
            slot="media"
            :class="`accordion__icon accordion__icon--${accordionItem.name
              .toLowerCase()
              .replaceAll(' ', '')}`"
          ></f7-icon>
          <f7-accordion-content>
            <f7-list>
              <f7-list-item
                v-for="(
                  subMenuItem, subMenuIndex
                ) in checkAllowedSubMenus(accordionItem.subMenu)"
                :key="`${subMenuItem.title}_${subMenuIndex}`"
                style="padding-left: 40px"
                class="listItemLink"
                :link="
                  subMenuItem.subMenu && !subMenuItem.navigatesToPage
                    ? ''
                    : subMenuItem.url
                "
                :title="$t(subMenuItem.title)"
                transition="f7-fade"
                :accordion-item="subMenuItem.subMenu ? true : false"
                @click="itemClick"
              >
                <f7-accordion-content v-if="subMenuItem.subMenu">
                  <f7-list class="marginLeft25">
                    <f7-list-item
                      v-for="(
                        menuItem, menuIndex
                      ) in subMenuItem.subMenu"
                      :key="`${menuItem.title}_${menuIndex}`"
                      class="listItemLink"
                      :link="menuItem.url"
                      :title="$t(menuItem.title)"
                      transition="f7-fade"
                      @click="itemClick"
                    >
                    </f7-list-item>
                  </f7-list>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </ul>
    </f7-list>

    <f7-list
      accordion-list
      class="list no-chevron no-hairlines no-hairlines-between no-margin-top"
    >
      <ul>
        <f7-list-item
          link="/OffersList/"
          :title="$t('My_offers')"
          transition="f7-fade"
          @click="itemClick"
        >
          <f7-icon
            slot="media"
            class="accordion__icon--my_offers item-media accordion__icon"
          >
          </f7-icon>
        </f7-list-item>
      </ul>
    </f7-list>

    <div class="left-panel__footer">
      <div class="color-primary">Besafer AGQ</div>
      <div class="small">{{ getReleaseVersion }}</div>
    </div>
  </f7-panel>
</template>

<script>
import { APP_LOGO, RELEASE_VERSION } from '@/js/constants';
import homeIcon from '@/static/LeftPanel/icons/ic_home.svg';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'LeftPanel',
  props: {
    isPanelOpen: {
      type: Boolean,
      default: true,
    },
    breakpoint: {
      type: Number,
      default: 1280,
    },
    panelOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      APP_LOGO,
      homeIcon,
    };
  },
  computed: {
    getReleaseVersion() {
      return RELEASE_VERSION;
    },
    ...mapState('contact', [
      'hasAccessToAdvanceService',
      'enabled',
      'hasAccessToNutritionalMonitoring',
    ]),
    ...mapGetters('user', ['isAdmin']),
    ...mapState('contact', ['company']),
    ...mapState('PowerBiModules', ['availableCountries']),
  },
  beforeMount() {
    this.fetchCountriesAvailables();
  },
  mounted() {
    this.selectDefaultOption();
  },
  methods: {
    selectDefaultOption() {
      const homeOption =
        document.getElementsByClassName('item-title')[0];
      homeOption.style.fontWeight = 'bold';
      homeOption.style.color = '#595295';

      document
        .getElementsByClassName('no-hairlines-between')[0]
        .children[0].children[0].classList.add(
          'accordion-item-opened'
        );
    },
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, {
        animate: true,
        transition: 'f7-fade',
      });
    },
    itemClick(e) {
      window.gtag('event', 'Click', {
        event_category: 'Menu',
        event_label: e.target.innerText,
      });
      Array.from(document.querySelectorAll('.item-title')).forEach(
        (itemTitle) => {
          itemTitle.style.fontWeight = 'normal';
          itemTitle.style.color = 'black';
        }
      );

      if (e.target.classList[0] === 'item-title') {
        e.target.style.fontWeight = 'bold';
        e.target.style.color = '#595295';
      } else if (
        e.path[0].children[0].classList[0] === 'item-title'
      ) {
        e.path[0].children[0].style.fontWeight = 'bold';
        e.path[0].children[0].style.color = '#595295';
      } else if (
        e.path[0].children[0].children[0] !== undefined &&
        e.path[0].children[0].children[0].classList[0] ===
          'item-title'
      ) {
        e.path[0].children[0].children[0].style.fontWeight = 'bold';
        e.path[0].children[0].children[0].style.color = '#595295';
      } else {
        e.path[0].children[0].style.fontWeight = 'bold';
        e.path[0].children[0].style.color = '#595295';
      }
    },
    checkAllowedSubMenus(subMenu) {
      const companyPrefix = this.company.substring(0, 4);

      const isAvailableCountry = this.availableCountries.some(
        (country) =>
          country.category === companyPrefix && country.active
      );

      return subMenu.filter((item) => {
        if (
          item.url === '/agronomy/advanced-services' &&
          this.enabled &&
          !this.hasAccessToAdvanceService
        ) {
          return false;
        }
        if (
          item.url === '/agronomy/nutritionalMonitoring' &&
          !this.hasAccessToAdvanceService &&
          !this.hasAccessToNutritionalMonitoring
        ) {
          return false;
        }
        if (item.url === '/StatsQueries/' && !isAvailableCountry) {
          return false;
        }
        return true;
      });
    },
    ...mapActions('PowerBiModules', ['fetchCountriesAvailables']),
  },
};
</script>

<style lang="scss" scoped>
@import './LeftPanel.styles.scss';
</style>
